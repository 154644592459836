import React from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import StatisticsCards from "./enquiries/statisticsCards";
import EnquiriesList from "./enquiries/enquiriesList";

const Home = () => {
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            Home
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <StatisticsCards />
            <Separator className="mb-4 mt-4" />
            <EnquiriesList />
          </Colxx>
        </Row>
      </div>
    </AppLayout>
  );
};

export default Home;
