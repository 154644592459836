import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import ListingTable from "components/ListingTable";
import moment from "moment";

const EnquiriesList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/enquiries?page=${currentPage}&per_page=10`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          const { enquiries, count, page, pageCount } = result.data;
          setData(enquiries);
          setCount(count);
          setCurrentPage(page);
          setTotalPage(pageCount);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (currentPage, selectedPageSize, word) => {
    ///
    axios
      .get(
        `/enquiries?search=${word}&page=${currentPage}&per_page=${selectedPageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const { enquiries, count, page, pageCount } = result.data;
        setData(enquiries);
        setCount(count);
        setCurrentPage(page);
        setTotalPage(pageCount);
        setLoading(false);
      });
  };

  const cols = React.useMemo(
    () => [
      {
        Header: "#",
        // accessor: "title",
        cellClass: "list-item-heading",
      },
      {
        Header: "eSim",
        accessor: "sims",
        cellClass: "list-item-heading w-10",
        Cell: (props) => (
          <>
            <img
              className="listing-image"
              alt="QR code"
              src={props.value[0]?.qrcode_url}
            />
          </>
        ),
      },
      {
        Header: "Transaction Date",
        accessor: "createdAt",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY")}</>,
      },
      {
        Header: "Package",
        accessor: "package",
        cellClass: "list-item-heading w-25",
        Cell: (props) => (
          <>
            {/* <NavLink to={`${props.row.original._id}`}>{props.value}</NavLink> */}
            {props.value}
          </>
        ),
      },
      {
        Header: "User Phone",
        accessor: "user",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value?.phoneNumber}</>,
      },
      {
        Header: "Price",
        accessor: "price",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Type",
        accessor: "topup",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value ? "Top Up" : "New eSim"}</>,
      },
    ],
    []
  );

  return (
    <div>
      <ListingTable
        columns={cols}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleSearch={handleSearch}
        totalPage={totalPage}
        totalItemCount={count}
        loading={loading}
      />
    </div>
  );
};

export default EnquiriesList;
