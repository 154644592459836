export const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(0, 54, 90)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

export const CHART_COLORS_ALPHA = {
  red: "rgba(255, 99, 132, 0.5)",
  orange: "rgba(255, 159, 64, 0.5)",
  yellow: "rgba(255, 205, 86, 0.5)",
  green: "rgba(75, 192, 192, 0.5)",
  blue: "rgba(0, 54, 90, 0.5)",
  purple: "rgba(153, 102, 255, 0.5)",
  grey: "rgba(201, 203, 207, 0.5)",
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
