import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import ListingTable from "components/ListingTable";
import moment from "moment";
import GlideComponent from "components/carousel/GlideComponent";
import IconCard from "components/cards/IconCard";
import CategoriesBarChart from "components/CategoriesBarChart";
import { ordersDataConstruct } from "utils/ordersDataConstruct";
import TgUsersList from "./tgUsersList";
import { currencyFormatter } from "utils/formatter";

const StatisticsCards = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/statistics`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          setData(result.data);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <div>
          <div className="icon-cards-row">
            <GlideComponent
              settings={{
                gap: 5,
                perView: 4,
                type: "carousel",
                breakpoints: {
                  320: { perView: 2 },
                  576: { perView: 3 },
                  1600: { perView: 4 },
                  1800: { perView: 4 },
                },
                hideNav: true,
              }}
            >
              <IconCard
                className="mb-4"
                icon="iconsminds-business-man-woman"
                title="No. of Users"
                value={data.usersCount}
              />
              <IconCard
                className="mb-4"
                icon="simple-icon-basket-loaded"
                title="eSims Purchased"
                value={data.purchasedSims}
              />
              <IconCard
                className="mb-4"
                icon="simple-icon-credit-card"
                title="Sales"
                value={currencyFormatter("" + data.totalPaymentAmount, "$")}
              />
              <IconCard
                className="mb-4"
                icon="simple-icon-diamond"
                title="Earned"
                value={currencyFormatter(
                  "" +
                    (data.totalPaymentAmount -
                      data.totalPrice +
                      data.totalPrice * 0.15),
                  "$"
                )}
              />
            </GlideComponent>
          </div>
          <Row>
            <Colxx md="6">
              <div className="mt-5">
                <CategoriesBarChart
                  title={"Orders"}
                  data={ordersDataConstruct(data.monthlyResult)}
                />
              </div>
            </Colxx>
            <Colxx md="6" className="stop-step">
              <TgUsersList />
            </Colxx>
          </Row>
        </div>
      )}
    </>
  );
};

export default StatisticsCards;
