import React, { useEffect, useState } from "react";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import ListingTable from "components/ListingTable";
import moment from "moment";

const TgUsersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/users?page=${currentPage}&per_page=10`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          const { users, count, page, pageCount } = result.data;
          setData(users);
          setCount(count);
          setCurrentPage(page);
          setTotalPage(pageCount);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (currentPage, selectedPageSize, word) => {
    ///
    axios
      .get(
        `/users?search=${word}&page=${currentPage}&per_page=${selectedPageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const { users, count, page, pageCount } = result.data;
        setData(users);
        setCount(count);
        setCurrentPage(page);
        setTotalPage(pageCount);
        setLoading(false);
      });
  };

  const cols = React.useMemo(
    () => [
      {
        Header: "#",
        // accessor: "title",
        cellClass: "list-item-heading w-10",
      },
      {
        Header: "User",
        accessor: "phoneNumber",
        cellClass: "list-item-head w-25",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Stop Step",
        accessor: "state",
        cellClass: "list-item-heading w-15",
        Cell: (props) => (
          <>
            {/* <NavLink to={`${props.row.original._id}`}>{props.value}</NavLink> */}
            {props.value}
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY HH:mm")}</>,
      },
    ],
    []
  );

  return (
    <div>
      <ListingTable
        columns={cols}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleSearch={handleSearch}
        totalPage={totalPage}
        totalItemCount={count}
        loading={loading}
      />
    </div>
  );
};

export default TgUsersList;
