const data = [
  {
    id: "companies",
    icon: "iconsminds-office",
    label: "Companies",
    to: `/app/companies`,
    access: ["Admin", "Manager"],
  },
  {
    id: "stations",
    icon: "iconsminds-location-2",
    label: "Stations",
    to: `/app/stations`,
    access: ["Admin", "Manager"],
  },
  {
    id: "feedbacks",
    icon: "iconsminds-speach-bubble-comic-2",
    label: "Feedbacks",
    to: `/app/feedbacks`,
    access: ["Admin", "Manager"],
  },
  {
    id: "prices",
    icon: "iconsminds-financial",
    label: "Prices",
    to: `/app/prices`,
    access: ["Admin", "Manager"],
  },
  {
    id: "fuels",
    icon: "iconsminds-refinery",
    label: "Fuels",
    to: `/app/fuels`,
    access: ["Admin", "Manager"],
  },
  {
    id: "users",
    icon: "iconsminds-conference",
    label: "Users",
    to: `/app/users`,
    access: ["Admin"],
  },
];
export default data;
