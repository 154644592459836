export const currencyFormatter = (value = "", symbol, left = true) => {
  if (value) {
    // console.log("value", value);
    return `${left ? symbol : ""}${value.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    )}${!left ? (symbol === "" ? symbol : ` ${symbol}`) : ""}`;
  } else {
    return null;
  }
};
