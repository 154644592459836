import React from "react";

import TopNav from "containers/navs/Topnav";
import Sidebar from "containers/navs/Sidebar";

const AppLayout = ({ children }) => {
  return (
    <div id="app-container">
      <TopNav />
      {/* <Sidebar /> */}
      {/* <main> */}
      <br />
      <br />
      <div className="container-fluid">{children}</div>
      {/* </main> */}
    </div>
  );
};

export default AppLayout;
