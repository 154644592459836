import { CHART_COLORS, CHART_COLORS_ALPHA, months } from "./Constants";

export const ordersDataConstruct = (data) => {
  let result = [];
  let resultData = [];
  let labels = [];
  for (let i = 0; i < data.length; i++) {
    labels.push(months[data[i]._id.month - 1]);
    resultData.push(data[i].totalOrders);
    result.push({
      title: months[data[i]._id.month - 1],
      total: data[i].totalOrders,
    });
  }
  return {
    data: result,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          borderColor: CHART_COLORS.blue,
          backgroundColor: CHART_COLORS_ALPHA.blue,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
      titles: labels,
    },
  };
};
