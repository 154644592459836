export const regionOptions = [
  { label: "Andijan", value: "Andijan" },
  { label: "Bukhara", value: "Bukhara" },
  { label: "Fergana", value: "Fergana" },
  { label: "Jizzakh", value: "Jizzakh" },
  { label: "Karakalpakstan", value: "Karakalpakstan" },
  { label: "Kashkadaryo", value: "Kashkadaryo" },
  { label: "Khorazm", value: "Khorazm" },
  { label: "Namangan", value: "Namangan" },
  { label: "Navoi", value: "Navoi" },
  { label: "Samarkand", value: "Samarkand" },
  { label: "Sirdaryo", value: "Sirdaryo" },
  { label: "Surkhondaryo", value: "Surkhondaryo" },
  { label: "Tashkent", value: "Tashkent" },
  { label: "Tashkent Region", value: "Tashkent Region" },
];

export const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const statusOptionsWithBlocked = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Blocked", value: "Blocked" },
];
