import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import { statusOptionsWithBlocked } from "constants/selectValues";
import { toast } from "react-toastify";

const initialValues = {
  username: "",
  password: "",
  role: "",
  status: "Active",
};

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState({});
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let data = { ...values, image };

    axios
      .post(`${isNew ? "/admin/register" : `/admin/${id}`}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/users");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleSelectChange = (e, name) => {
    setValues({ ...values, [name]: e.value });
  };

  const handleDeleteUser = () => {
    axios
      .delete(`/admin/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/users");
        }
      });
  };

  const handleImage = async (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();
    // console.log(fileType);

    if (!correctImageTypes.includes(fileType)) {
      toast.error("Only image files can be uploaded.");
    } else {
      setPreview(window.URL.createObjectURL(file));
      setLoading(true);
      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      await axios.post(
        "/remove-image",
        { image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImage({});
      setPreview("");
      setValues({ ...values, loading: false });
    } catch (err) {
      console.log(err);
      setValues({ ...values, loading: false });
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/admin/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result.data;
          setValues(data);
          if (data.image) {
            setImage(data.image);
            setPreview(data.image.Location);
          }
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} User</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Label>Profile</Label>
                          <Input
                            type="file"
                            name="image"
                            onChange={handleImage}
                            accept="image/*"
                            disabled={values.loading}
                          />
                        </FormGroup>
                        {preview && (
                          <>
                            <div class="position-absolute img-top-button">
                              <Button
                                outline
                                color="danger"
                                onClick={handleImageRemove}
                                className="icon-button btn btn-outline-red"
                              >
                                x
                              </Button>
                            </div>
                            <img
                              alt={"logo"}
                              src={preview}
                              className="card-img-top"
                            />
                          </>
                        )}
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Last Name</Label>
                          <Input
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup md="4">
                          <Label>Role</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="role"
                            value={{
                              label: values.role,
                              value: values.role,
                            }}
                            onChange={(e) => handleSelectChange(e, "role")}
                            options={[
                              { label: "User", value: "User" },
                              { label: "Operator", value: "Operator" },
                              { label: "Manager", value: "Manager" },
                              { label: "Admin", value: "Admin" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <Label>Username</Label>
                          <Input
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            value={{
                              label: values.status,
                              value: values.status,
                            }}
                            name="status"
                            className="react-select mb-3"
                            classNamePrefix="react-select"
                            options={statusOptionsWithBlocked}
                            onChange={(e) => handleSelectChange(e, "status")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div className="centered">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/users")}
          >
            Cancel
          </Button>
          {/* {!isNew && (
            <Button
              color="primary"
              className="top-right-button"
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          )} */}
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default AddUser;
